import Vue from 'vue'
import VueRouter from 'vue-router'
import Other from './Other.js'
import Sysset from './Sysset.js'
import Power from './Power.js'
import Finance from './Finance.js'
import User from './User.js'
import UserAuth from './UserAuth.js'
import Notice from './Notice.js'
import News from './News.js'
import SlideShow from './SlideShow.js'
import FeedBack from './FeedBack.js'
import Import from './Import.js'
import Reward from './Reward.js'

Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
    ...Other,
    ...Sysset,
    ...Power,
    ...Finance,
    ...User,
    ...UserAuth,
    ...Notice,
    ...News,
    ...SlideShow,
    ...FeedBack,
    ...Import,
    ...Reward
]

const router = new VueRouter({
  routes
})

export default router
