import Vue from 'vue'
import axios from 'axios'
import router from './router'
Vue.prototype.ApiUrl = 'https://shadowapi.danyegou.com';
Vue.prototype.wsUrl = 'wss://shadowapi.danyegou.com/wss';
//Vue.prototype.ApiUrl = 'http://api.tianyue.cc';
//Vue.prototype.wsUrl = 'ws://api.tianyue.cc:9520';
const out_time=21600;
Vue.prototype.openurl = function (url,query) {
    const queryparams = {
        ...query
    };
    this.$router.push({path:url,query:queryparams});
};
Vue.prototype.opentags = function (url,query) {
    for (let i in router.options.routes){
        if(url==router.options.routes[i].path){
            this.$store.commit('add_tagsview',Object.assign(router.options.routes[i],{'query':query}));
            break;
        }
    }
    const queryparams = {
        ...query
    };
    this.$router.push({path:url,query:queryparams});
};
Vue.prototype.requestApi=function (url,data,headers){
    let header=Object.assign({'content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},headers);
    return new Promise((resolve) => {
        axios.interceptors.request.use(
            config => {
                const token = this.getToken();
                token && (config.headers.authorize = token);
                return config;
            },error => {
                return Promise.error(error);
        });
        axios({
            method: typeof data == "object" ? 'POST' : 'GET',
            url: this.ApiUrl + url,
            data: data ? data : {},
            headers: header,
            responseType: 'json',
        }).then(res => {
            if (res.status == 200) {
                if (res.data.status == 403) {
                    resolve({'status':403,'msg':''});
                    this.alertError(res.data.msg);
                }else if(res.data.status == 401){
                    this.$store.commit('add_exclude',{name:'Dashboard'});
                    this.$store.commit('clear_tagsview');
                    this.openurl('/login');
                }else {
                    resolve(res.data);
                }
            }
        }).catch(err => {
            this.alertError('请求失败',4.5);
            console.log(err);
        })
    })
}
Vue.prototype.alertError=function (txt,duration){
    if(txt!=''){
        this.$Message.error({
            background: true,
            content: txt,
            duration:duration || 6,
            closable:duration?false:true
        });
    }
    return false;
}
Vue.prototype.tipSuccess=function (txt){
    this.$Message.success({
        background: true,
        content: txt,
        duration:3,
    });
    return false;
}
Vue.prototype.confirm=function (obj,succfn,failfn){
    let msgtxt=typeof obj=='object'?obj.msg:obj,
        okText=(typeof obj=='object' && obj.okText)?obj.okText:'确定',
        cancelText=(typeof obj=='object' && obj.cancelText)?obj.cancelText:'取消';
    this.$Modal.confirm({
        render: (h) => {
            return h('div', {
                class:'comm-confirm'
            },[
                h('Icon',{
                    props:{
                        type:'ios-help-circle',
                        size:24,
                        color:'#ff9900'
                    }
                }),msgtxt
            ])
        },
        okText:okText,
        cancelText:cancelText,
        onOk: function () {
            if(typeof succfn=='function') succfn();
        },
        onCancel: function () {
            if(typeof failfn=='function') failfn();
        }
    })
}
var delay_status=0;
Vue.prototype.getToken=function(){
    let token=localStorage.getItem('token');
    if(token && !delay_status){
        let aout_time=localStorage.getItem('aout_time'),now=new Date().getTime();
        now=parseInt(now.toString().substr(0,10));
        if(!aout_time || (aout_time-now) < out_time){
            delay_status=1;
            this.requestApi('/adm/delay.html').then(function (res){
                if(res.status==1){
                    localStorage.setItem('aout_time',res.out_time);
                    delay_status=0;
                }else{
                    delay_status=0;
                }
            })
        }
    }
    return token;
}

Vue.prototype.requestExcel=function (url,title,params){
    if(typeof params=="undefined") params={page:1};
    else params=JSON.parse(JSON.stringify(params));
    let data=[],_this=this;
    return new Promise((resolve) => {
        beg();
        function beg(){
            _this.requestApi(url,params).then(function (res){
                if(res.status!=1) resolve(res);
                if(res.data.current_page<res.data.last_page){
                    data.push.apply(data,_this.build_excel_data(title,res.data.data));
                    params.page++;
                    beg();
                }else{
                    data.push.apply(data,_this.build_excel_data(title,res.data.data));
                    data.unshift(Object.values(title));
                    resolve({'status':1,'data':data});
                }
            })
        }
    })
}

Vue.prototype.build_excel_data=function (title,data){
    let newData=[];title=Object.keys(title);
    for (let i=0;i<data.length;i++){
        newData[i]=[];
        for (var j=0;j<title.length;j++){
            newData[i].push(data[i][title[j]]);
        }
    }
    data=null;
    return newData;
}

Vue.prototype.data_filter=function (str,ftype,round){
    if(ftype=='int'){
        str=str.replace(/[^\d]/g,"");
        str=str.replace(/^0*/g,"");
    }else if (ftype=='float'){
        str=str.replace(/[^\d.]/g,"");
        str=str.replace(/\.{2,}/g,".");
        str=str.replace(".","$#$").replace(/\./g,"").replace("$#$",".");
        if(round==1) str=str.replace(/^(-)*(\d+)\.(\d).*$/,'$1$2.$3');
        else if(round==2) str=str.replace(/^(-)*(\d+)\.(\d\d).*$/,'$1$2.$3');
        else if(round==3) str=str.replace(/^(-)*(\d+)\.(\d\d\d).*$/,'$1$2.$3');
        else if(round==4) str=str.replace(/^(-)*(\d+)\.(\d\d\d\d).*$/,'$1$2.$3');
        else str=str.replace(/^(-)*(\d+)\.(\d\d\d\d\d).*$/,'$1$2.$3');
    }
    return str;
}
Vue.prototype.close_page=function (route){
    var state=this.$store.state.tagsview.allviews;
    for (let [i, v] of state.entries()) {
        if (v.path == route.path) {
            if(state[i-1]) this.openurl(state[i-1].path);
            else this.openurl('/');
        }
    }
    this.$store.commit('del_tagsview',route);
}