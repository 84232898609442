export default [
  {
    path: '/syssetting',
    name: 'Syssetting',
    component: () => import('../views/sysset/syssetting.vue'),
    meta:{
      title:'系统设置'
    }
  },
  {
    path: '/bank_mana',
    name: 'BankMana',
    component: () => import('../views/sysset/bank_mana/bank_list.vue'),
    meta:{
      title:'银行卡管理'
    }
  },
  {
    path: '/sms_setting',
    name: 'SmsSetting',
    component: () => import('../views/sysset/sms_setting.vue'),
    meta:{
      title:'短信配置'
    }
  },
  {
    path: '/oss_setting',
    name: 'OssSetting',
    component: () => import('../views/sysset/oss_setting.vue'),
    meta:{
      title:'存储配置'
    }
  },
  {
    path: '/memhpage',
    name: 'Memhpage',
    component: () => import('../views/sysset/memhpage.vue'),
    meta:{
      title:'首页设置'
    }
  },
  {
    path: '/poster',
    name: 'Poster',
    component: () => import('../views/sysset/poster.vue'),
    meta:{
      title:'海报列表'
    }
  },
  {
    path: '/posteredit',
    name: 'PosterEdit',
    component: () => import('../views/sysset/posteredit.vue'),
    meta:{
      title:'编辑海报'
    }
  },
  {
    path: '/kefu',
    name: 'Kefu',
    component: () => import('../views/sysset/kefu.vue'),
    meta:{
      title:'客服设置'
    }
  }
]
