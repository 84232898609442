export default [
  {
    path: '/super',
    name: 'Super',
    component: () => import('../views/power/super.vue'),
    meta:{
      title:'管理员列表'
    }
  },
  {
    path: '/sgroup',
    name: 'SuperGroup',
    component: () => import('../views/power/sgroup.vue'),
    meta:{
      title:'角色管理'
    }
  },
  {
    path: '/superlog',
    name: 'SuperLog',
    component: () => import('../views/power/superlog.vue'),
    meta:{
      title:'操作日志'
    }
  }
]
