export default [
  {
    path: '/moneylog',
    name: 'MoneyLog',
    component: () => import('../views/finance/moneylog.vue'),
    meta:{
      title:'资金流水'
    }
  },
  {
    path: '/allwithraw',
    name: 'allwithraw',
    component: () => import('../views/finance/allwithraw.vue'),
    meta:{
      title:'提现列表'
    }
  },
]
