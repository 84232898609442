import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import './comfun'
import Viewer from "v-viewer"
import 'viewerjs/dist/viewer.css'
import VueLazyLoad from 'vue-lazyload'
Vue.config.productionTip = false
Vue.use(ViewUI);
Vue.use(Viewer);
Vue.use(VueLazyLoad,{
  error: require('./assets/error.png'),
  loading: require('./assets/loading.gif')
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#tianyue');
Viewer.setDefaults({
  Options: {
    'inline': true, // 启用 inline 模式
    'button': true, // 显示右上角关闭按钮
    'navbar': true, // 显示缩略图导航
    'title': true, // 显示当前图片的标题
    'toolbar': true, // 显示工具栏
    'tooltip': true, // 显示缩放百分比
    'movable': true, // 图片是否可移动
    'zoomable': true, // 图片是否可缩放
    'rotatable': true, // 图片是否可旋转
    'scalable': true, // 图片是否可翻转
    'transition': true, // 使用 CSS3 过度
    'fullscreen': true, // 播放时是否全屏
    'keyboard': true, // 是否支持键盘
    'url': 'data-source' // 设置大图片的 url
  }
})
