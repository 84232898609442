export default [
	{
		path:'/real',
		name:'RealName',
		component:()=>import('../views/user_auth/real'),
		meta:{
			title:'实名认证'
		}
	},
	{
		path:'/company',
		name:'Company',
		component:()=>import('../views/user_auth/company'),
		meta:{
			title:'企业认证'
		}
	}
]
