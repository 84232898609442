export default [
    {
        path: '/slide_list',
        name: 'SlideList',
        component: () => import('../views/slide_list/index.vue'),
        meta:{
            title:'晒图奖励'
        }
    }
]
