export default [
    {
        path: '/send_log',
        name: 'SendLog',
        component: () => import('../views/notice/send_log'),
        meta:{
            title:'发送记录'
        }
    },
    {
        path: '/send_mail',
        name: 'SendMail',
        component: () => import('../views/notice/send_mail'),
        meta:{
            title:'发送站内信'
        }
    }
]
