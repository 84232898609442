const tagsview= {
  state: {
    exclude: '',
    allviews: []
  },
  mutations: {
    /**
     * 添加访问导航标签
     */
    add_tagsview:function (state, view){
      let hasin=false;
      state.allviews.some(function (v,key){
        if(v.path == view.path){
          hasin=true;
          if(v.query!=view.query){
            state.allviews[key].query=view.query;
          }
          return false;
        }
      })
      if(hasin) return false;
      state.exclude = '';
      state.allviews.push({
        name: view.name,
        path: view.path,
        title: view.meta.title || '--',
        query: view.query || {}
      })
    },
    /**
     * 删除访问导航标签
     */
    del_tagsview:function (state, view){
      for (let [i, v] of state.allviews.entries()) {
        if (v.path == view.path) {
          state.exclude = v.name;
          state.allviews.splice(i, 1);
          break;
        }
      }
    },
    /**
     * 清空所有标签
     */
    clear_tagsview:function (state){
      state.exclude='';
      for (let i in state.allviews){
        state.exclude+=state.exclude==''?state.allviews[i].name:','+state.allviews[i].name;
      }
      state.allviews=[];
    },
    /**
     * 添加禁止路由缓存
     */
    add_exclude:function (state,view){
      state.exclude = view.name;
    },
    /**
     * 清空禁止缓存
     */
    clear_exclude:function (state){
      state.exclude = '';
    }
  },
  actions: {
  },
  modules: {
  }
};
export default tagsview
