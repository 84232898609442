export default [
  {
    path: '/userlist',
    name: 'UserList',
    component: () => import('../views/user/userlist.vue'),
    meta:{
      title:'用户列表'
    }
  },
  {
    path: '/teamlist',
    name: 'TeamList',
    component: () => import('../views/user/teamlist.vue'),
    meta:{
      title:'我的团队'
    }
  }
]
