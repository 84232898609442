<template>
  <div class="dashboard">
    <div class="welcom">
      <Icon class="uicon" type="ios-cafe" />
      您好 <span class="uname">{{$store.state.uinfo.group_name||''}} / {{$store.state.uinfo.relname||''}}</span>，欢迎使用<span class="uname">{{data.pname||''}}</span>后台管理系统。现在时间是：{{nowTime}}
    </div>
    <div class="tips">
      <Icon class="ticon" type="ios-notifications" />
      本页数据会自动更新，无需手动刷新~
    </div>
    <div class="dashlis" v-if="loaded1">
      <div class="dastit"><span class="dasico iconfont">&#xe62c;</span>待处理事项</div>
      <div class="dalsw">
        <div class="lisitem">
          <div class="dalis" @click="opentags('/company?status=2&newpage=1')">
            <div class="watico iconfont" style="font-size: 30px; color: #69c0ff;">&#xe6bf;</div>
            <div class="wattext">企业认证待审核(<span :class="[data.company?'warring':'']">{{data.company||0}}</span>)</div>
          </div>
          <div class="dalis" @click="opentags('/allwithraw?status=1&newpage=1')">
            <div class="watico iconfont" style="font-size: 28px; color: #b37feb;">&#xe67c;</div>
            <div class="wattext">提现待审核(<span :class="[data.with_wcheck?'warring':'']">{{data.with_wcheck||0}}</span>)</div>
          </div>
          <div class="dalis" @click="opentags('/allwithraw?status=2&newpage=1')">
            <div class="watico iconfont" style="font-size: 28px; color: #ff85c0;">&#xe7d0;</div>
            <div class="wattext">提现待打款(<span :class="[data.with_wmoney?'warring':'']">{{data.with_wmoney||0}}</span>)</div>
          </div>
          <div class="dalis" @click="opentags('/slide_list?status=2&newpage=1')">
            <div class="watico iconfont" style="font-size: 28px; color: #95de64;">&#xe6b2;</div>
            <div class="wattext">晒图待审核(<span :class="[data.slide_wcheck?'warring':'']">{{data.slide_wcheck||0}}</span>)</div>
          </div>
          <div class="dalis" @click="opentags('/slide_list?status=1&newpage=1')">
            <div class="watico iconfont" style="font-size: 28px; color: #FF9900;">&#xe7d0;</div>
            <div class="wattext">晒图待打款(<span :class="[data.slide_wmoney?'warring':'']">{{data.slide_wmoney||0}}</span>)</div>
          </div>
          <div class="dalis" @click="opentags('/feedback_list?status=1&newpage=1')">
            <div class="watico iconfont" style="font-size: 28px; color: #5cdbd3;">&#xe60e;</div>
            <div class="wattext">反馈待回复(<span :class="[data.feedback?'warring':'']">{{data.feedback||0}}</span>)</div>
          </div>
        </div>
      </div>
    </div>
    <div class="dayout" v-if="loaded2">
      <div class="wdatals">
        <div class="wdtit">访问人数(人)<span class="td1">今日</span></div>
        <div class="wdcon">
          <div class="wdlsmd">{{preview.dvisit||0}}</div>
          <div class="fardata">
            <span class="farle">本月访问人数</span>
            <span class="farri">{{preview.mvisit||0}}人</span>
          </div>
        </div>
      </div>
      <div class="wdzw"></div>
      <div class="wdatals">
        <div class="wdtit">新注册会员(人)<span class="td2">今日</span></div>
        <div class="wdcon">
          <div class="wdlsmd">{{preview.dreg||0}}</div>
          <div class="fardata">
            <span class="farle">本月新注册会员</span>
            <span class="farri">{{preview.mreg||0}}人</span>
          </div>
        </div>
      </div>
      <div class="wdzw"></div>
      <div class="wdatals">
        <div class="wdtit">提现申请(元/笔)<span class="td3">今日</span></div>
        <div class="wdcon">
          <div class="wdlsmd">{{preview.dwith.totmoney||0}}/{{preview.dwith.tot||0}}</div>
          <div class="fardata">
            <span class="farle">本月提现申请</span>
            <span class="farri">{{preview.mwith.totmoney||0}}元/{{preview.mwith.tot||0}}笔</span>
          </div>
        </div>
      </div>
      <div class="wdzw"></div>
      <div class="wdatals">
        <div class="wdtit">提现打款(元/笔)<span class="td4">今日</span></div>
        <div class="wdcon">
          <div class="wdlsmd">{{preview.dwmon.totmoney||0}}/{{preview.dwmon.tot||0}}</div>
          <div class="fardata">
            <span class="farle">本月提现打款</span>
            <span class="farri">{{preview.mwmon.totmoney||0}}元/{{preview.mwmon.tot||0}}笔</span>
          </div>
        </div>
      </div>
      <div class="wdzw"></div>
      <div class="wdatals">
        <div class="wdtit">导入奖励(元)<span class="td5">今日</span></div>
        <div class="wdcon">
          <div class="wdlsmd">{{preview.dimon||0}}</div>
          <div class="fardata">
            <span class="farle">本月导入奖励</span>
            <span class="farri">{{preview.mimon||0}}元</span>
          </div>
        </div>
      </div>
    </div>
    <LineChart :datas="preview.linedata" v-if="loaded2"></LineChart>
  </div>
</template>

<script>
import LineChart from '@/components/dashboard/lineChart'
export default {
  name: 'Dashboard',
  components:{LineChart},
  data(){
    return {
      nowTime:'--',
      data:{},
      preview:{dwith:{tot:0,totmoney:0},mwith:{tot:0,totmoney:0},dwmon:{tot:0,totmoney:0},mwmon:{tot:0,totmoney:0},linedata:{date:[],data:[]}},
      timer:{
        timer1:null,
        timer2:null,
        timer3:null
      },
      loaded1:false,
      loaded2:false
    }
  },
  activated(){
    clearInterval(this.timer.timer1);
    clearInterval(this.timer.timer2);
    this.timer.timer1=setInterval(this.getTime,1000);
    this.getWait();
    this.getPreview();
    this.timer.timer2=setInterval(this.getWait,10000);
    this.timer.timer3=setInterval(this.getPreview,20000);
  },
  methods:{
    /**
     * 刷新
     */
    reload:function (){
      this.getWait();
      this.getPreview();
    },
    /**
     * 获取当前时间
     */
    getTime:function (){
      let date=new Date(),
          Y=date.getFullYear(),
          m=date.getMonth()+1,
          d=date.getDate(),
          H=date.getHours(),
          i=date.getMinutes(),
          s=date.getSeconds();
      m= (m+1) < 10 ? "0" + m.toString() : m;
      d= d < 10 ? "0" + d.toString() : d;
      H= H < 10 ? "0" + H.toString() : H;
      i= i < 10 ? "0" + i.toString() : i;
      s= s < 10 ? "0" + s.toString() : s;
      this.nowTime=Y+'-'+m+'-'+d+' '+H+':'+i+':'+s;
    },
    /**
     * 获取待处理数据
     */
    getWait:function (){
      var _this=this;
      this.requestApi('/adm/wait_data.html').then(function (res){
        if(res.status==1){
          _this.data=res.data;
          _this.loaded1=true;
        }
      })
    },
    /**
     * 获取数据预览
     */
    getPreview:function (){
      var _this=this;
      this.requestApi('/adm/preview_data.html').then(function (res){
        if(res.status==1){
          _this.preview=res.data;
          _this.loaded2=true;
        }
      })
    }
  },
  beforeRouteLeave(to,from,next) {
    clearInterval(this.timer.timer1);
    clearInterval(this.timer.timer2);
    clearInterval(this.timer.timer3);
    next();
  }
}
</script>
<style lang="less">
@import "~@/assets/css/dashboard/dashboard";
</style>
