<template>
  <div id="tianyue" :class="[showHead?(navmode=='mini'?'mininav':''):'fullleft']">
    <leftnav v-if="showHead" :mode="navmode"></leftnav>
    <div class="yrmain" :class="navmode=='mini'?'yrbig':''">
      <div class="topnav" v-if="showHead">
        <div class="tlmuen">
          <a href="javascript:void(0)" class="tnavbtn iconfont" :title="[navmode=='normal'?'收缩侧边栏':'展开侧边栏']" @click="mmNav">{{navmode=='normal'?'&#xe7f4;':'&#xe7f3;'}}</a>
          <a href="javascript:void(0)" class="tnavbtn iconfont" title="刷新当前数据" @click="reload">&#xe788;</a>
          <a href="javascript:void(0)" class="tnavbtn iconfont" :title="[isfull?'取消全屏':'全屏']" @click="fullscreen_togggle">{{isfull?'&#xe768;':'&#xe781;'}}</a>
        </div>
        <div class="trlinfo">
          <div class="login-admin" @click="showout=1" @mouseleave="showout=0">
            {{$store.state.uinfo.group_name||''}} / {{$store.state.uinfo.relname||''}}<Icon class="icon" type="ios-arrow-down" />
            <div class="outact" v-if="showout">
              <div class="abtnls" @click="opentags('/editpwd')"><Icon class="abticon" type="md-key" />修改密码</div>
              <div class="abtnls" @click="loginout"><Icon class="abticon" type="md-log-out" />退出登录</div>
            </div>
          </div>
        </div>
      </div>
      <tagsview v-if="showHead"></tagsview>
      <div class="main-content" :style="[{'backgroundImage':'url('+domain+waterimg+')'}]">
        <keep-alive :exclude="$store.state.tagsview.exclude">
          <router-view v-if="isRouterAlive" ref="nroute"></router-view>
        </keep-alive>
      </div>
    </div>
  </div>
</template>
<script>
import leftnav from '@/components/common/leftnav.vue'
import tagsview from '@/components/common/tagsview.vue'
export default {
  components: {leftnav,tagsview},
  data() {
    return {
      showout:0,
      isfull:0,
      showHead:true,
      isRouterAlive:true,
      navmode:'normal',
      domain:this.ApiUrl,
      waterimg:'/static/water/nowater.png'
    }
  },
  created() {
    if(this.$route.name=='Login') this.showHead=false;
    this.get_water();
  },
  watch:{
    '$route.path':function(path,old){
      if(old=='/login') this.$store.commit('add_exclude',{name:'Login'});
      this.showHead=path=='/login'?false:true;
      if(this.$route.path!='/login' && this.$route.path!='/') this.$store.commit('add_tagsview',this.$route);
    }
  },
  methods:{
    /**
     * 全屏/退出全屏
     */
    fullscreen_togggle:function (){
      if(this.isfull){
        this.isfull=0;
        this.exit_fullscreen();
      }else{
        this.isfull=1;
        this.fullscreen();
      }
    },
    fullscreen:function (){
      var docElm = document.documentElement;
      if (docElm.requestFullscreen) {//W3C
        docElm.requestFullscreen();
      }else if (docElm.mozRequestFullScreen) {//FireFox
        docElm.mozRequestFullScreen();
      }else if (docElm.webkitRequestFullScreen) {//Chrome等
        docElm.webkitRequestFullScreen();
      }else if (docElm.msRequestFullscreen) {//IE11
        docElm.msRequestFullscreen();
      }
    },
    exit_fullscreen:function (){
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    /**
     * 退出登录
     */
    loginout:function (){
      var _this=this;
      this.requestApi('/adm/login_out.html').then(function (res){
        if(res.status==1){
          _this.showout=0;
          localStorage.removeItem('token');
          _this.openurl('/login');
          _this.tipSuccess(res.msg);
        }else{
          _this.alertError(res.msg);
        }
      })
    },
    /**
     * 刷新
     */
    reload:function (){
      if(this.$refs['nroute'].reload) this.$refs['nroute'].reload();
      else if(this.$refs['nroute'].init) this.$refs['nroute'].init();
    },
    /**
     * 左侧菜单折叠/展开
     */
    mmNav:function (){
      this.navmode=this.navmode=='normal'?'mini':'normal';
    },
    /**
     * 获取水印图片
     */
    get_water:function (){
      var _this=this;
      this.requestApi('/adm/get_water.html').then(function (res){
        if(res.status==1) _this.waterimg=res.img;
      })
    }
  }
}
</script>
<style lang="less">
@import "~@/assets/css/common/app";
</style>
