export default [
    {
        path: '/import',
        name: 'Import',
        component: () => import('../views/import_m/import.vue'),
        meta:{
            title:'导入业绩'
        }
    },
    {
        path: '/import_list',
        name: 'ImportList',
        component: () => import('../views/import_m/import_list.vue'),
        meta:{
            title:'导入列表'
        }
    },
]
