export default [
  {
    path: '/newslist',
    name: 'NewsList',
    component: () => import('../views/news/newslist.vue'),
    meta:{
      title:'新手指南'
    }
  },
  {
    path: '/editnews',
    name: 'EditNews',
    component: () => import('../views/news/editnews.vue'),
    meta:{
      title:'编辑新手指南'
    }
  }
]
