<template>
  <div id="navmenu" :class="[mode=='mini'?'menumini':'']">
    <div class="navnormal" v-if="mode=='normal'">
      <div class="menulis">
        <div class="menutxt" @click="openurl('/')"><span class="icon iconfont">&#xe6b2;</span>主控台</div>
      </div>
      <div class="menulis" v-for="(mu,key) in data" :key="key">
        <div class="menutxt" @click="openmenu($event,mu,1)">
          <span class="icon iconfont" v-html="mu.icon"></span>
          {{mu.name}}
          <Icon class="sicon" type="ios-arrow-down" v-if="mu.children && mu.children.length" />
        </div>
        <div class="child" v-if="mu.children && mu.children.length">
          <div class="menulis" v-for="(me,ke) in mu.children" :key="ke">
            <div class="menutxt" @click="openmenu($event,me,2)">
              {{me.name}}
              <Icon class="sicon" type="ios-arrow-down" v-if="me.children && me.children.length" />
            </div>
            <div class="children" v-if="me.children && me.children.length">
              <div class="menutxt" v-for="(p,k) in me.children" :key="k" @click="opentags(p.route_url)">{{p.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="navsmall" v-else>
      <div class="mulis">
        <Poptip trigger="hover" content="主控台" placement="right" popper-class="tooltip" transfer>
          <div class="muico iconfont" @click="openurl('/')">&#xe6b2;</div>
        </Poptip>
      </div>
      <div class="mulis" v-for="(mu,key) in data" :key="key" @mouseenter="openchild($event,mu,key,-1)" @mouseleave="hidechild(key,-1)">
        <Poptip trigger="hover" :content="mu.name" placement="right" popper-class="tooltip" transfer v-if="!mu.children || !mu.children.length">
          <div class="muico iconfont" v-html="mu.icon" @click="opentags(mu.route_url)"></div>
        </Poptip>
        <div class="muico iconfont" v-html="mu.icon" v-else></div>
        <div class="child" :class="[mu.showchild?(mu.direction=='top'?'childshow':'childshow bottom'):(mu.direction=='top'?'':'bottom')]" v-if="mu.children && mu.children.length">
          <div class="menulis" v-for="(me,ke) in mu.children" :key="ke" @mouseenter="openchild($event,me,key,ke)" @mouseleave="hidechild(key,ke)">
            <div class="menutxt" @click="(!me.children || !me.children.length) && opentags(me.route_url)">
              {{me.name}}
              <Icon class="sicon" type="ios-arrow-forward" v-if="me.children && me.children.length" />
            </div>
            <div class="children" :class="[me.showchild?(me.direction=='top'?'childshow':'childshow bottom'):(me.direction=='top'?'':'bottom')]" v-if="me.children && me.children.length">
              <div class="menutxt" v-for="(p,k) in me.children" :key="k" @click="opentags(p.route_url)">{{p.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'leftnav',
  props:{
    mode:{
      type:String,
      default:'normal'
    }
  },
  data(){
    return {
      data:[],
      halfH:0
    }
  },
  created() {
    this.getNav();
  },
  mounted() {
    this.halfH=parseInt(document.documentElement.offsetHeight/2);
    var _this=this;
    window.onresize=function (){
      _this.halfH=parseInt(document.documentElement.offsetHeight/2);
    }
  },
  methods:{
    /**
     * 获取菜单
     */
    getNav:function (){
      var _this=this;
      this.requestApi('/adm/getmenu.html').then(function (res){
        if(res.status==1){
          _this.data=res.data;
          _this.$store.commit('up_uinfo',res.uinfo);
        }
      })
    },
    /**
     * 宽菜单模式 菜单点击
     */
    openmenu:function (event,data,level){
      if(data.children.length){ //展开/收缩
       let silderClass=level==1?'children silderdown':'child silderdown',hideClass=level==1?'children':'child';
       if(event.target.nextSibling.classList.contains('silderdown')==true){
         event.target.nextSibling.className=hideClass;
         event.target.lastChild.className='sicon ivu-icon ivu-icon-ios-arrow-down';
       }else{
         event.target.nextSibling.className=silderClass;
         event.target.lastChild.className='sicon silderico ivu-icon ivu-icon-ios-arrow-down';
       }
      }else{//跳转路由
        this.opentags(data.route_url);
      }
    },
    /**
     * 迷你菜单 鼠标经过
     */
    openchild:function (event,data,key1,key2){
      if(data.children.length){
        if (key1>-1 && key2>-1){
          let mu1H=this.data[key1].children.length*25;
          if(event.target.parentNode.parentNode.offsetTop>(this.halfH-mu1H)) this.$set(this.data[key1].children[key2],'direction','bottom');
          else this.$set(this.data[key1].children[key2],'direction','top');
          this.$set(this.data[key1].children[key2],'showchild',true);
        }else{
          if(event.target.offsetTop>this.halfH) this.$set(this.data[key1],'direction','bottom');
          else this.$set(this.data[key1],'direction','top');
          this.$set(this.data[key1],'showchild',true);
        }
      }
    },
    /**
     * 迷你菜单 鼠标移开
     */
    hidechild:function (key1,key2){
      if(key1>-1 && key2>-1) this.$set(this.data[key1].children[key2],'showchild',false);
      else this.$set(this.data[key1],'showchild',false);
    }
  }
}
</script>
<style lang="less">
@import "~@/assets/css/common/leftnav";
</style>
