<template>
  <div ref="echart" class="dlchartw"></div>
</template>

<script>
var echarts = require('echarts');
export default {
  name: 'LineChart',
  props:{
    datas:{
      type:Object,
      default:function (){
        return {};
      },
      required: true
    }
  },
  data(){
    return {};
  },
  mounted() {
    var _this=this;
    this.initEchartMap();
    window.onresize=function (){
      const myChart = echarts.init(_this.$refs.echart);
      myChart.resize();
    };
  },
  watch:{
    datas(){
      this.initEchartMap();
    }
  },
  methods:{
    initEchartMap:function (){
      const myChart = echarts.init(this.$refs.echart);
      var option = {
        title: {
          text: '近30天导入奖励金额变化曲线图',
          textStyle:{
            color:'#111',
            fontSize:16
          },
          x:'center',
          y:10,
          textAlign:'left'
        },
        grid:{
          left:10,
          top:50,
          right:5,
          bottom:5,
          containLabel:true
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          axisLabel:{
            interval:0,
            rotate:30
          },
          data: this.datas.date
        },
        yAxis: {
          type: 'value',
        },
        series: [{
          name:'导入奖励金额',
          data: this.datas.data,
          type: 'line'
        }]
      };
      myChart.setOption(option);
    }
  }
}
</script>
<style lang="less">
.dlchartw{
  width: 100%;
  height: 400px;
  margin-top: 10px;
}
</style>
