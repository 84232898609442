export default [
    {
        path: '/feedback_list',
        name: 'FeedBackList',
        component: () => import('../views/feedback/feedback_list.vue'),
        meta:{
            title:'客户反馈'
        }
    }
]
