import Vue from 'vue'
import Vuex from 'vuex'
import tagsview from './tagsview'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    uinfo:{}
  },
  mutations: {
    up_uinfo:function (state,val){
      state.uinfo=val;
    }
  },
  actions: {
  },
  modules: {
    tagsview
  }
})
