<template>
  <div class="tagsview">
    <div class="tagiconl iconfont" @click="scroll($event,'left')">&#xe8f2;</div>
    <div class="tagslist">
      <div class="tagls" :class="[isActive({path:'/'})?'tagcur':'']" @click="openurl('/')">主控台</div>
      <div class="tagls" :class="[isActive(p)?'tagcur':'']" v-for="(p,key) in data" :key="key" @click="opentags(p.path,p.query)">{{p.title}}<span class="tagico iconfont" @click.stop="close_tag(p)">&#xe7fd;</span></div>
    </div>
    <div class="tagiconr iconfont" @click="scroll($event,'right')">&#xe8f3;</div>
  </div>
</template>
<script>
export default {
  name: 'tagsview',
  data(){
    return{
      data:this.$store.state.tagsview.allviews
    }
  },
  methods:{
    isActive(route) {
      return route.path == this.$route.path;
    },
    /**
     * 关闭标签
     */
    close_tag:function (route){
      var state=this.$store.state.tagsview.allviews;
      for (let [i, v] of state.entries()) {
        if (v.path == route.path) {
          if(state[i-1]) this.openurl(state[i-1].path,state[i-1].query);
          else this.openurl('/');
        }
      }
      this.$store.commit('del_tagsview',route);
    },
    scroll:function (event,dire){
      if(dire=='right'){//向右
        event.target.previousSibling.scrollLeft+=200
      }else{//向左
        event.target.nextSibling.scrollLeft-=200
      }
    }
  }
}
</script>
<style lang="less">
@import "~@/assets/css/common/tagsview";
</style>
