import Login from "@/views/login/login";
import Dashboard from "@/views/dashboard";
export default [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta:{
      title:'主控台'
    }
  },
  {
    path: '/editpwd',
    name: 'Editpwd',
    component: () => import('../views/login/editpwd.vue'),
    meta:{
      title:'修改密码'
    }
  },
  {
    path: '/smscode',
    name: 'SmsCode',
    component: () => import('../views/attach/smscode.vue'),
    meta:{
      title:'手机验收码'
    }
  },
  {
    path: '/qrcode',
    name: 'Qrcode',
    component: () => import('../views/attach/qrcode.vue'),
    meta:{
      title:'推广码'
    }
  }
]
