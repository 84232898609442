export default [
  {
    path: '/platform',
    name: 'Platform',
    component: () => import('../views/reward/platform/platform.vue'),
    meta:{
      title:'平台管理'
    }
  },
  {
    path: '/user_level',
    name: 'userLevel',
    component: () => import('../views/reward/user_level/index.vue'),
    meta:{
      title:'等级管理'
    }
  }
]
