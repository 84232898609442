<template>
  <div class="login-home">
    <div class="login-table">
      <div class="login-cell">
        <div class="loginw">
          <div class="login-title">管理员登录</div>
          <div class="inplis">
            <Input class="linps" placeholder="用户名" maxlength="15" size="large" v-model="user_name" :disabled="login_loading"><span slot="prepend" class="iconfont">&#xe7ae;</span></Input>
          </div>
          <div class="inplis">
            <Input class="linps" placeholder="密码" type="password" maxlength="32" size="large" v-model="user_pwd" :disabled="login_loading"><span slot="prepend" class="iconfont">&#xe7c9;</span></Input>
          </div>
          <div class="inplis">
            <Input class="linps" placeholder="口令" type="password" maxlength="32" size="large" v-model="user_word" :disabled="login_loading" @on-enter="sublogin"><span slot="prepend" class="iconfont">&#xe696;</span></Input>
          </div>
          <div class="inplis">
            <Button type="primary" class="sublogin" @click="sublogin" :loading="login_loading">登录</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from 'js-md5';
import gt from '@/assets/js/common/gt'
export default {
  name: 'Login',
  data() {
    return {
      user_name:'',
      user_pwd:'',
      user_word:'',
      gt:{gt_server_status:'',gt_user_id:''},
      login_loading:false,
      geetest:{}
    }
  },
  mounted() {
    this.getuuid();
    let token=this.getToken();
    if(token){
      this.openurl('/');
    }else{
      this.$store.commit('add_exclude',{name:'Dashboard'});
      this.$nextTick(function (){
        this.$store.commit('clear_tagsview');
      })
    }
  },
  methods:{
    sublogin:function (){
      if(this.user_name=='') return this.alertError('请填写用户名！');
      if(this.user_pwd=='') return this.alertError('请填写密码！');
      if(this.user_word=='') return this.alertError('请填写口令！');
      this.geetest.verify();
    },
    getuuid:function (){
      var _this=this;
      this.requestApi('/geetest.html').then(function (data){
        _this.gt.gt_user_id=data.gt_user_id;
        _this.gt.gt_server_status=data.gt_server_status;
        gt.initGeetest({
          gt: data.gt,
          challenge: data.challenge,
          new_captcha: data.new_captcha,
          product: "bind",
          offline: !data.success
        }, _this.handler);
      })
    },
    handler:function (captchaObj){
      var _this=this;
      captchaObj.onSuccess(function () {
        var result = captchaObj.getValidate();
        if (!result)  return _this.alertError('请完成验证码验证!');
        _this.login_loading=true;
        let salt=_this.randomString(),user_pwd=md5(md5(_this.user_pwd)+salt),user_word=md5(md5(_this.user_word)+salt);
        let data={'user_name':_this.user_name,'user_pwd':user_pwd,'user_word':user_word,'salt':salt,'geetest_challenge': result.geetest_challenge,'geetest_validate': result.geetest_validate,'geetest_seccode':result.geetest_seccode,gt_user_id:_this.gt.gt_user_id,gt_server_status:_this.gt.gt_server_status};
        _this.requestApi('/adm/login.html',data).then(function (res){
          if(res.status==1){
            localStorage.setItem('token',res.token.token);
            localStorage.setItem('aout_time',res.token.out_time);
            setTimeout(function (){_this.openurl('/')},1000)
            _this.tipSuccess(res.msg);
          }else{
            _this.login_loading=false;
            _this.alertError(res.msg);
          }
        })
      });
      _this.geetest=captchaObj;
    },
    randomString:function (){
      let char='ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz0123456789',maxPos=char.length,salt='';
      for (var i = 0; i < 10; i++) {
        salt += char.charAt(Math.floor(Math.random() * maxPos));
      }
      return salt;
    }
  }
}
</script>
<style lang="less">
@import "~@/assets/css/login/login";
</style>